import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Image,
    Input,
    Text,
    InputGroup,
    InputRightAddon,
} from '@chakra-ui/react';
import { Field, Formik, FormikProps } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import { FormContext } from './../../utils/Context';
import { useTranslation } from 'react-i18next';
import { KiloToPounds, PoundsToKilo } from './../../utils/Conversion';
import { Experience } from '../../utils/Enums';

function validateSize(value: string) {
    let error;
    if (!value) {
        error = 'Size is required';
    } else if (parseInt(value) > 29 || parseInt(value) < 23) {
        error = 'Size is wrong';
    }
    return error;
}

export function FormikSelect() {
    const Form = useContext(FormContext);
    const { t, i18n } = useTranslation();
    const [selectedModel, selectModel] = useState<string>('');
    const [selectedActivity, selectActivity] = useState<string>('');
    const [prevExp, selectPrevExp] = useState<Experience | undefined>(
        undefined
    );
    const [sizeClicked, setSizeClicked] = useState<Boolean>(false);
    const activityEl = useRef<HTMLButtonElement>(null);
    const expEl = useRef<HTMLButtonElement>(null);
    const weightEl = useRef<HTMLButtonElement>(null);
    const sumbitRef = useRef<HTMLButtonElement>(null);
    const sizeEl = useRef<HTMLButtonElement>(null);
    const weightInputEl = useRef<HTMLInputElement>(null);
    const sizeInputEl = useRef<HTMLInputElement>(null);
    const isSizeDisabled = (
        props: FormikProps<{ ShoeSize: string | number; Weight: string }>
    ) => {
        return (
            selectedModel === '' ||
            selectedActivity === '' ||
            prevExp === undefined ||
            props.values.Weight === '' ||
            props.errors.Weight !== undefined
        );
    };
    function FocusWeight() {
        setTimeout(() => {
            if (
                weightEl &&
                weightEl.current &&
                weightEl.current.getAttribute('aria-expanded') === 'false'
            ) {
                weightEl.current.click();
                setTimeout(() => {
                    if (weightInputEl && weightInputEl.current) {
                        weightInputEl.current.focus();
                    }
                }, 300);
            }
        }, 500);
    }
    function validateWeight(value: number) {
        //Should probably be something like useMemo or something since this is rerendered constantly now.
        let error;
        if (!value) {
            error = 'Weight is required';
        } else if (i18n.language === 'us') {
            if (value > KiloToPounds(150)! || value < KiloToPounds(40)!) {
                error = 'Weight is wrong';
            }
        } else {
            if (value > 150 || value < 40) {
                error = 'Weight is wrong';
            }
        }
        if (!error) {
            setTimeout(() => {
                if (
                    sizeEl &&
                    sizeEl.current &&
                    sizeEl.current.getAttribute('aria-expanded') === 'false' &&
                    !sizeClicked
                ) {
                    sizeEl?.current?.click();
                    setSizeClicked(true);
                    setTimeout(() => {
                        if (sizeInputEl && sizeInputEl.current) {
                            sizeInputEl.current.focus();
                        }
                    }, 300);
                }
            }, 300);
        }
        return error;
    }

    return (
        <Formik
            initialValues={{
                ShoeSize: Form.inputValues.size || '',
                Weight:
                    (i18n.language === 'us'
                        ? KiloToPounds(Form.inputValues.weight)?.toFixed(0) ||
                          ''
                        : Form.inputValues.weight?.toFixed(0)) || '',
            }}
            onSubmit={(values, actions) => {
                const parsedWeight =
                    i18n.language === 'us'
                        ? PoundsToKilo(parseInt(values.Weight as string))
                        : parseInt(values.Weight as string);
                const parsedValues = {
                    size: parseInt(values.ShoeSize as string),
                    weight: Math.round(parsedWeight),
                    selected: true,
                    model: selectedModel,
                    activity: selectedActivity,
                    previousExperience: prevExp,
                };
                Form.changeValue(parsedValues);
            }}
            validateOnChange
        >
            {(props) => (
                <form
                    onSubmit={props.handleSubmit}
                    autoComplete="off"
                    style={{ width: '100%', position: 'relative' }}
                >
                    <Accordion allowToggle={true} defaultIndex={0}>
                        <AccordionItem w="100%">
                            <AccordionButton
                                bg="#dadad9"
                                color="#6c757d"
                                _expanded={{ bg: 'lindhe.500', color: '#FFF' }}
                                _focus={{ outline: 'none' }}
                            >
                                <Box flex="1" textAlign="left" fontSize="xl">
                                    {t('kneeOption')}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                                pb={4}
                                display="flex"
                                bg="lindhe.600"
                            >
                                <Button
                                    w={{
                                        base: '30%',
                                        sm: '30%',
                                        md: '30%',
                                        lg: '30%',
                                        xl: '30%',
                                    }}
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    p="8px"
                                    marginRight="8px"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    border="2px"
                                    borderColor={
                                        selectedModel === 'TT'
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectModel('TT');
                                        setTimeout(() => {
                                            if (
                                                activityEl &&
                                                activityEl.current
                                            ) {
                                                activityEl.current.click();
                                            }
                                        }, 100);
                                    }}
                                >
                                    <Image
                                        src="tt.png"
                                        alt="Below knee"
                                        height="70px"
                                        filter="invert(1)"
                                    ></Image>
                                    <Text color="#FFF">TT</Text>
                                </Button>
                                <Button
                                    w={{
                                        base: '30%',
                                        sm: '30%',
                                        md: '30%',
                                        lg: '30%',
                                        xl: '30%',
                                    }}
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    p="8px"
                                    border="2px"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    borderColor={
                                        selectedModel === 'TF'
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectModel('TF');
                                        setTimeout(() => {
                                            if (
                                                activityEl &&
                                                activityEl.current
                                            ) {
                                                activityEl.current.click();
                                            }
                                        }, 100);
                                    }}
                                >
                                    <Image
                                        src="tf.png"
                                        alt="Above knee"
                                        height="70px"
                                        filter="invert(1)"
                                    ></Image>
                                    <Text color="#FFF">TF</Text>
                                </Button>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem
                            w="100%"
                            isDisabled={selectedModel === ''}
                        >
                            <AccordionButton
                                ref={activityEl}
                                bg="#dadad9"
                                color="#6c757d"
                                _expanded={{ bg: 'lindhe.500', color: '#FFF' }}
                                _focus={{ outline: 'none' }}
                            >
                                <Box flex="1" textAlign="left" fontSize="xl">
                                    {t('activityLevel')}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                                pb={4}
                                display="flex"
                                bg="lindhe.600"
                            >
                                <Button
                                    height="100%"
                                    display="flex"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    flexDirection="column"
                                    p="8px"
                                    marginRight="8px"
                                    border="2px"
                                    borderColor={
                                        selectedActivity === 'Low'
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectActivity('Low');
                                        setTimeout(() => {
                                            if (expEl && expEl.current) {
                                                expEl.current.click();
                                            }
                                        }, 100);
                                    }}
                                >
                                    <Text color="#FFF">K3 - {t('LOW')}</Text>
                                </Button>
                                <Button
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    p="8px"
                                    marginRight="8px"
                                    border="2px"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    borderColor={
                                        selectedActivity === 'Moderate'
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectActivity('Moderate');
                                        setTimeout(() => {
                                            if (expEl && expEl.current) {
                                                expEl.current.click();
                                            }
                                        }, 100);
                                    }}
                                >
                                    <Text color="#FFF">
                                        K3 - {t('MODERATE')}
                                    </Text>
                                </Button>
                                <Button
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    p="8px"
                                    border="2px"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    borderColor={
                                        selectedActivity === 'High'
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectActivity('High');
                                        setTimeout(() => {
                                            if (expEl && expEl.current) {
                                                expEl.current.click();
                                            }
                                        }, 100);
                                    }}
                                >
                                    <Text color="#FFF">K3 - {t('HIGH')}</Text>
                                </Button>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem
                            w="100%"
                            isDisabled={
                                selectedModel === '' || selectedActivity === ''
                            }
                        >
                            <AccordionButton
                                ref={expEl}
                                bg="#dadad9"
                                color="#6c757d"
                                _expanded={{ bg: 'lindhe.500', color: '#FFF' }}
                                _focus={{ outline: 'none' }}
                            >
                                <Box flex="1" textAlign="left" fontSize="xl">
                                    {t('previousExperience')}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                                pb={4}
                                display="flex"
                                bg="lindhe.600"
                            >
                                <Button
                                    height="100%"
                                    display="flex"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    flexDirection="column"
                                    p="8px"
                                    marginRight="8px"
                                    border="2px"
                                    whiteSpace="unset"
                                    borderColor={
                                        prevExp === Experience.NotApplicable
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectPrevExp(Experience.NotApplicable);
                                        FocusWeight();
                                    }}
                                >
                                    <Text color="#FFF">
                                        {t('NOT_APPLICABLE')}
                                    </Text>
                                </Button>
                                <Button
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    p="8px"
                                    marginRight="8px"
                                    border="2px"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    whiteSpace="unset"
                                    borderColor={
                                        prevExp === Experience.Soft
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectPrevExp(Experience.Soft);
                                        FocusWeight();
                                    }}
                                >
                                    <Text color="#FFF">{t('SOFT')}</Text>
                                </Button>
                                <Button
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    p="8px"
                                    border="2px"
                                    boxShadow="md"
                                    _focus={{ bg: '#696969' }}
                                    _hover={{ bg: '#696969' }}
                                    whiteSpace="unset"
                                    borderColor={
                                        prevExp === Experience.Stiff
                                            ? 'lindhe.500'
                                            : 'white'
                                    }
                                    bg="transparent"
                                    onClick={() => {
                                        selectPrevExp(Experience.Stiff);
                                        FocusWeight();
                                    }}
                                >
                                    <Text color="#FFF">{t('STIFF')}</Text>
                                </Button>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem
                            w="100%"
                            isDisabled={
                                selectedModel === '' ||
                                selectedActivity === '' ||
                                prevExp === undefined
                            }
                        >
                            <AccordionButton
                                ref={weightEl}
                                bg="#dadad9"
                                color="#6c757d"
                                _focus={{ outline: 'none' }}
                                _expanded={{ bg: 'lindhe.500', color: '#FFF' }}
                            >
                                <Box flex="1" textAlign="left" fontSize="xl">
                                    {t('Weight')}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4} bg="lindhe.600">
                                <Field
                                    name="Weight"
                                    type="number"
                                    min={
                                        i18n.language === 'us'
                                            ? KiloToPounds(40)
                                            : 40
                                    }
                                    max={
                                        i18n.language === 'us'
                                            ? KiloToPounds(150)
                                            : 150
                                    }
                                    validate={validateWeight}
                                >
                                    {({
                                        field,
                                        form,
                                    }: {
                                        field: any;
                                        form: any;
                                    }) => (
                                        <FormControl isRequired my={4}>
                                            <FormLabel
                                                htmlFor="Weight"
                                                color="#fff"
                                                requiredIndicator={<></>}
                                            >
                                                {t('Weight')}
                                            </FormLabel>
                                            <InputGroup>
                                                <Input
                                                    id="Weight"
                                                    {...field}
                                                    type="number"
                                                    isInvalid={
                                                        form.errors.Weight &&
                                                        form.touched.Weight
                                                    }
                                                    ref={weightInputEl}
                                                    bg="#FFF"
                                                    errorBorderColor="red.300"
                                                    aria-describedby="weight-helper-text"
                                                    roundedRight="0"
                                                />
                                                <InputRightAddon
                                                    children={t('wUnit')}
                                                />
                                            </InputGroup>
                                            <FormHelperText
                                                color="#FFF"
                                                id="weight-helper-text"
                                            >
                                                {t('EnterWeight')}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Field>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem
                            w="100%"
                            isDisabled={isSizeDisabled(props)}
                        >
                            <AccordionButton
                                ref={sizeEl}
                                bg={'#dadad9'}
                                color={'#6c757d'}
                                _focus={{ outline: 'none' }}
                                _expanded={{ bg: 'lindhe.500', color: '#FFF' }}
                            >
                                <Box flex="1" textAlign="left" fontSize="xl">
                                    {t('ShoeSize')}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4} bg="lindhe.600">
                                <Field
                                    name="ShoeSize"
                                    type="number"
                                    min={23}
                                    max={29}
                                    validate={validateSize}
                                >
                                    {({
                                        field,
                                        form,
                                    }: {
                                        field: any;
                                        form: any;
                                    }) => (
                                        <FormControl isRequired my={4}>
                                            <FormLabel
                                                htmlFor="ShoeSize"
                                                color="#fff"
                                                requiredIndicator={<></>}
                                            >
                                                {t('ShoeSize')}
                                            </FormLabel>
                                            <InputGroup>
                                                <Input
                                                    id="ShoeSize"
                                                    {...field}
                                                    ref={sizeInputEl}
                                                    type="number"
                                                    isInvalid={
                                                        form.errors.ShoeSize &&
                                                        form.touched.ShoeSize
                                                    }
                                                    bg="#FFF"
                                                    errorBorderColor="red.300"
                                                    aria-describedby="ShoeSize-helper-text"
                                                    roundedRight="0"
                                                />
                                                <InputRightAddon children="cm" />
                                            </InputGroup>
                                            <FormHelperText
                                                color="#fff"
                                                id="ShoeSize-helper-text"
                                            >
                                                {t('EnterShoeSize')}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Field>
                            </AccordionPanel>
                        </AccordionItem>
                        <Button
                            mt={4}
                            ref={sumbitRef}
                            w="80%"
                            mx="auto"
                            bg="lindhe.500"
                            color="white"
                            _hover={{ color: '#faa183' }}
                            isDisabled={
                                props.errors.ShoeSize !== undefined ||
                                props.errors.Weight !== undefined ||
                                props.values.ShoeSize === '' ||
                                props.values.Weight === '' ||
                                prevExp === undefined ||
                                selectedModel === '' ||
                                selectedActivity === ''
                            }
                            display="block"
                            type="submit"
                        >
                            {t('SubmitValues')}
                        </Button>
                    </Accordion>
                </form>
            )}
        </Formik>
    );
}
